<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="config.id != null">
            <div>Редактирование<br> конфигурации id {{ config.id }}</div>
          </h1>
          <h1 v-else>
            <div>Создание конфигурации паспорта офиса ЦЗН
              <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
              <input ref="fileInput" style="display: none" type="file" @change="loadJson">
            </div>
          </h1>

          <div v-if="config.id != null" class="creation-date">
            Дата создания<span>{{ config.createdDate | dateFormat }}</span></div>
          <div v-if="config.id != null" class="creation-date">
            Дата изменения<span v-if="config.lastModifiedDate">{{ config.lastModifiedDate | dateFormat }}</span>
            <span v-else>—</span>
          </div>

          <v-row>
            <v-col cols="12" lg="3">
              <div class="date-range-label">Период действия</div>
              <v-menu
                  v-model="beginDateMenu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="config.content.beginDate | dateFormat"
                      clearable
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="config.content.beginDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="beginDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="3">
              <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="config.content.endDate | dateFormat"
                      clearable
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="config.content.endDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="endDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <div class="list config">
            <div class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel
                    v-for="(section, sectionIndex) in config.content.sections"
                    :key="`section_${sectionIndex}`"
                >
                  <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>
                          <h4 v-if="sectionIndex === 0">
                            Разделы паспорта
                          </h4>
                          <q>{{ sectionIndex + 1 }}.</q>
                          <v-text-field
                              v-model="section.name"
                              @click.stop="">
                          </v-text-field>
                        </span>
                        <div v-if="config.content.sections.length > 1"
                             class="delete"
                             @click="sectionDown(sectionIndex)"
                             @click.stop=""
                        >
                          <v-icon class="icon-item icon-arrow-down-bold-outline"></v-icon>
                        </div>
                        <div v-if="config.content.sections.length > 1"
                             class="delete"
                             @click="sectionUp(sectionIndex)"
                             @click.stop=""
                        >
                          <v-icon class="icon-item icon-arrow-up-bold-outline"></v-icon>
                        </div>
                        <div v-if="config.content.sections.length > 1"
                             class="delete"
                             @click="deleteSection(sectionIndex)"
                             @click.stop=""
                        >
                          <v-icon class="icon-item icon-delete-outline"></v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="list-item-wrap">
                      <v-expansion-panels>
                        <v-expansion-panel v-for="(indicator, indicatorIndex) in section.indicators"
                                           :key="`indicator_${indicatorIndex}`"
                                           class="level2"
                        >
                          <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                            <div class="list-item">
                              <div class="list-item-name">
                                <button class="toggleSwitch whiteBg">
                                  <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                  </svg>
                                  <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                  </svg>
                                </button>
                                <span>
                                  <h4 v-if="indicatorIndex === 0">
                                    Показатели
                                  </h4>
                                  <q>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}.</q>
                                   <v-combobox
                                       v-model="indicator.name"
                                       :items="indicators"
                                       item-text="name"
                                       return-object
                                       label="Наименование показателя"
                                       hide-no-data
                                       :hint="indicator.id ? `ID: ${indicator.id}` : `Будет создан новый показатель`"
                                       persistent-hint
                                       allow-overflow
                                       @input="val => changeIndicatorName(indicator, val)"
                                       @click.native.stop
                                   >
                                </v-combobox>
                                </span>
                                <div v-if="section.indicators.length > 1"
                                     class="delete"
                                     @click="indicatorDown(section, indicatorIndex)"
                                     @click.stop=""
                                >
                                  <v-icon class="icon-item icon-arrow-down-bold-outline"></v-icon>
                                </div>
                                <div v-if="section.indicators.length > 1"
                                     class="delete"
                                     @click="indicatorUp(section, indicatorIndex)"
                                     @click.stop=""
                                >
                                  <v-icon class="icon-item icon-arrow-up-bold-outline"></v-icon>
                                </div>
                                <div v-if="section.indicators.length > 1"
                                     class="delete"
                                     @click="deleteIndicator(sectionIndex, indicatorIndex)"
                                     @click.stop="">
                                  <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content class="level3">
                            <table class="config-table">
                              <tbody>
                              <tr>
                                <td>Тип</td>
                                <td>
                                  <v-select
                                      v-model="indicator.dataType"
                                      :items="dataTypes"
                                      item-text="name"
                                      item-value="code"
                                      label="Тип"
                                      @change="dataTypeChanged(indicator, $event)"
                                  ></v-select>
                                </td>
                                <td>
                                  <p>{{ indicator.dataType != null ? getDataTypeByCode(indicator.dataType).description : '' }}</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>

                            <div
                                v-if="indicator.dataType != null && getDataTypeByCode(indicator.dataType).code === 'OPTIONAL'">
                              <div class="list-item" v-for="(option, optionIndex) in indicator.options" :key="'option' + optionIndex">
                                <div class="list-item-name">
                                  <span><v-text-field v-model="option.value"></v-text-field></span>
                                  <div v-if="indicator.options.length > 1"
                                       class="delete"
                                       @click="deleteOption(indicator, optionIndex)"
                                       @click.stop="">
                                    <v-icon class="icon-item icon-delete-outline"></v-icon>
                                  </div>
                                </div>
                              </div>
                              <a class="configAdd" href="#" @click.prevent="addOption(indicator)">+ Значение справочника</a>
                            </div>
                            <v-checkbox
                                v-model="indicator.yearly"
                                label="Значения по годам">
                            </v-checkbox>

                            <v-expansion-panels>
                              <v-expansion-panel
                                  v-for="(subIndicator, subIndicatorIndex) in indicator.subIndicators"
                                  :key="`sub_indicator_${subIndicatorIndex}`"
                                  class="level2"
                              >
                                <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                                  <div class="list-item">
                                    <div class="list-item-name">
                                      <button class="toggleSwitch whiteBg">
                                        <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                        </svg>
                                        <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                        </svg>
                                      </button>
                                      <span>
                                  <h4 v-if="subIndicatorIndex === 0">
                                    Подпоказатели
                                  </h4>
                                  <q>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}.{{ subIndicatorIndex + 1 }}.</q>
                                    <v-combobox
                                        v-model="subIndicator.name"
                                        :items="indicators"
                                        item-text="name"
                                        return-object
                                        label="Наименование показателя"
                                        hide-no-data
                                        :hint="subIndicator.id ? `ID: ${subIndicator.id}` : `Будет создан новый показатель`"
                                        persistent-hint
                                        allow-overflow
                                        @input="val => changeIndicatorName(subIndicator, val)"
                                        @click.native.stop
                                    >
                                    </v-combobox>
                                </span>
                                      <div v-if="indicator.subIndicators.length > 1"
                                           class="delete"
                                           @click="subIndicatorDown(indicator, subIndicatorIndex)"
                                           @click.stop=""
                                      >
                                        <v-icon class="icon-item icon-arrow-down-bold-outline"></v-icon>
                                      </div>
                                      <div v-if="indicator.subIndicators.length > 1"
                                           class="delete"
                                           @click="subIndicatorUp(indicator, subIndicatorIndex)"
                                           @click.stop=""
                                      >
                                        <v-icon class="icon-item icon-arrow-up-bold-outline"></v-icon>
                                      </div>
                                      <div
                                          class="delete"
                                          @click="deleteSubIndicator(sectionIndex, indicatorIndex, subIndicatorIndex)"
                                          @click.stop="">
                                        <v-icon class="icon-item icon-delete-outline"></v-icon>
                                      </div>
                                    </div>
                                  </div>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content class="level3">
                                  <table class="config-table">
                                    <tbody>
                                    <tr>
                                      <td>Тип</td>
                                      <td>
                                        <v-select
                                            v-model="subIndicator.dataType"
                                            :items="dataTypes"
                                            item-text="name"
                                            item-value="code"
                                            label="Тип"
                                            @change="dataTypeChanged(subIndicator, $event)"
                                        ></v-select>
                                      </td>
                                      <td>
                                        <p>{{ subIndicator.dataType != null ? getDataTypeByCode(subIndicator.dataType).description : '' }}</p>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>

                                  <div
                                      v-if="subIndicator.dataType != null && getDataTypeByCode(subIndicator.dataType).code === 'OPTIONAL'">
                                    <div class="list-item" v-for="(option, optionIndex) in subIndicator.options" :key="'option' + optionIndex">
                                      <div class="list-item-name">
                                        <span><v-text-field v-model="option.value"></v-text-field></span>
                                        <div v-if="subIndicator.options.length > 1"
                                             class="delete"
                                             @click="deleteOption(subIndicator, optionIndex)"
                                             @click.stop="">
                                          <v-icon class="icon-item icon-delete-outline"></v-icon>
                                        </div>
                                      </div>
                                    </div>
                                    <a class="configAdd" href="#" @click.prevent="addOption(subIndicator)">+ Значение справочника</a>
                                  </div>
                                  <v-checkbox
                                      v-model="subIndicator.yearly"
                                      label="Значения по годам"></v-checkbox>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <a class="configAdd" href="#" @click.prevent="addSubIndicator(indicator)">+ Добавить подпоказатель</a>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>

                    </div>
                    <a class="configAdd" href="#" @click.prevent="addIndicator(section)">+ Добавить показатель</a>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <a class="configAdd" href="#" @click.prevent="addSection">+ Добавить раздел</a>
            </div>
          </div>

          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/passportczn')">Отмена</v-btn>
            <v-btn :disabled="loading" color="primary" @click="submit">Сохранить</v-btn>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import {createIndicator, getIndicatorsByDomain} from "@/modules/api.indicators";
import {getDomainConfigById, saveDomainConfig, updateDomainConfig} from "@/modules/api.configs";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: "PassportCZNConfigEdit",
  components: {LoaderDialog},
  data() {
    return {
      domain: 'passport_czn',
      indicators: [],
      dataTypes: [
        {code: 'INTEGER', name: 'Целое число', description: 'Целое число, возможно использование пробелов'},
        {code: 'DECIMAL', name: 'Дробное число', description: 'Дробное число, разделитель – запятая'},
        {code: 'TEXT', name: 'Текстовая строка', description: 'Строка длиной до 4000 символов'},
        {code: 'OPTIONAL', name: 'Справочник', description: 'Выбор из списка значений'},
      ],
      config: {
        id: null,
        content: {
          beginDate: null,
          endDate: null,
          sections: [{
            name: "",
            indicators: [{
              id: null,
              name: "",
              dataType: "INTEGER",
              yearly: false,
              subIndicators: [],
            }],
          }],
        },
      },
      beginDateMenu: false,
      endDateMenu: false,
      minDate: '2019-01-01',
      maxDate: '2030-12-31',
      errorText: null,
      loading: false,
      apiLoaded: false
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    changeIndicatorName(indicator, val) {
      if (val && typeof val === 'object') {
        indicator.id = val.id
        indicator.name = val.name
        indicator.dataType = val.dataType === 'STRING' ? 'TEXT' : val.dataType
        indicator.yearly = val.periodSpan === 'YEAR'
        indicator.options = val.options ? JSON.parse(val.options) : null
      } else {
        indicator.id = null
      }
    },
    async loadIndicators() {
      let req = await getIndicatorsByDomain(this.domain)
      if (req.ok) {
        this.indicators = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    //Досоздает индикаторы, которые не нашлись по названию
    async createIndicators() {
      for (const section of this.config.content.sections) {
        for (const indicator of section.indicators) {
          if (!indicator.id || !indicator.id.length) {
            await this.createIndicator(indicator)
          }
          if (indicator.subIndicators != null) {
            for (const subIndicator of indicator.subIndicators) {
              if (!subIndicator.id || !subIndicator.id.length) {
                await this.createIndicator(subIndicator)
              }
            }
          }
        }
      }
    },
    async createIndicator(indicator) {
      let dataType = indicator.dataType
      if (indicator.dataType === 'TEXT') {
        dataType = 'STRING'
      }

      const indicatorData = {
        domain: {
          "code": this.domain,
        },
        name: indicator.name,
        dataType: dataType,
        periodSpan: indicator.yearly ? 'YEAR' : null,
        options: indicator.options ? JSON.stringify(indicator.options) : null,
      }

      const req = (await createIndicator(indicatorData)).payload
      indicator.id = req.id
      return indicator
    },

    deleteSection(sectionIndex) {
      this.config.content.sections.splice(sectionIndex, 1)
      this.$forceUpdate()
    },
    deleteIndicator(sectionIndex, indicatorIndex) {
      const section = this.config.content.sections[sectionIndex]
      if (section && section.indicators[indicatorIndex]) {
        section.indicators.splice(indicatorIndex, 1)
      }
      this.$forceUpdate()
    },
    deleteSubIndicator(sectionIndex, indicatorIndex, subIndicatorIndex) {
      const section = this.config.content.sections[sectionIndex];
      if (section) {
        const indicator = section.indicators[indicatorIndex]
        if (indicator && indicator.subIndicators[subIndicatorIndex]) {
          indicator.subIndicators.splice(subIndicatorIndex, 1)
        }
      }
      this.$forceUpdate()
    },
    deleteOption(indicator, optionIndex) {
      indicator.options.splice(optionIndex, 1)
      this.$forceUpdate()
    },

    addSection() {
      if (this.config.content.sections == null) {
        this.config.content.sections = []
      }
      this.config.content.sections.push({
        name: "",
        indicators: [{
          id: null,
          name: "",
          dataType: 'INTEGER',
          subIndicators: [],
          yearly: false,
        }]
      })
      this.$forceUpdate()
    },
    addIndicator(section) {
      section.indicators.push({
        id: null,
        name: "",
        dataType: "INTEGER",
        subIndicators: [],
        yearly: false,
      })
      this.$forceUpdate()
    },
    addSubIndicator(indicator) {
      if (!indicator.subIndicators) {
        this.$set(indicator, 'subIndicators', [])
      }
      const newSubIndicator = {
        id: null,
        name: "",
        dataType: 'INTEGER',
        yearly: false,
      }

      indicator.subIndicators.push(newSubIndicator)
      this.$set(indicator, 'subIndicators', [...indicator.subIndicators])
      this.$forceUpdate()
    },
    addOption(indicator){
      if (indicator.options == null) {
        indicator.options = []
      }
      const newId = indicator.options.length
      indicator.options.push({
        id: newId,
        value: '',
      })
      this.$forceUpdate()
    },

    dataTypeChanged(indicator, dataType){
      if (dataType === 'OPTIONAL') {
        indicator.options = []
      } else {
        delete indicator.options
      }
      this.$forceUpdate()
    },
    validate(sections) {
      if (sections == null || sections.length < 1) {
        return "Должен быть хотя бы один раздел"
      }
      for (let i = 0; i < sections.length; i++) {
        let section = sections[i]
        if (section.name == null || section.name.length === 0) {
          return "Должны быть указаны названия всех элементов"
        }
        for (let j = 0; j < section.indicators.length; j++) {
          let indicator = section.indicators[j]
          if (indicator.name == null || indicator.name.length === 0) {
            return "Должны быть указаны названия всех элементов"
          }
          if (indicator.subIndicators != null)
            for (let k = 0; k < indicator.subIndicators; k++) {
              let subIndicator = indicator.subIndicators[k]
              if (subIndicator.name == null || subIndicator.name.length === 0) {
                return "Должны быть указаны названия всех элементов"
              }
            }
        }
      }
      return true
    },
    sectionUp(sectionIndex) {
      if (sectionIndex > 0) {
        const sections = this.config.content.sections
        const sectionToMove = sections[sectionIndex]
        sections.splice(sectionIndex, 1)
        sections.splice(sectionIndex - 1, 0, sectionToMove)
      }
    },
    sectionDown(sectionIndex) {
      if (sectionIndex < this.config.content.sections.length - 1) {
        const sections = this.config.content.sections;
        const sectionToMove = sections[sectionIndex];
        sections.splice(sectionIndex, 1);
        sections.splice(sectionIndex + 1, 0, sectionToMove);
      }
    },
    indicatorUp(section, indicatorIndex) {
      if (indicatorIndex > 0) {
        const indicators = section.indicators
        const indicatorToMove = indicators[indicatorIndex]
        indicators.splice(indicatorIndex, 1)
        indicators.splice(indicatorIndex - 1, 0, indicatorToMove)
      }
    },
    indicatorDown(section, indicatorIndex) {
      if (indicatorIndex < section.indicators.length - 1) {
        const indicators = section.indicators
        const indicatorToMove = indicators[indicatorIndex]
        indicators.splice(indicatorIndex, 1)
        indicators.splice(indicatorIndex + 1, 0, indicatorToMove)
      }
    },
    subIndicatorUp(indicator, subIndicatorIndex) {
      if (subIndicatorIndex > 0) {
        const subIndicators = indicator.subIndicators
        const subIndicatorToMove = subIndicators[subIndicatorIndex]
        subIndicators.splice(subIndicatorIndex, 1)
        subIndicators.splice(subIndicatorIndex - 1, 0, subIndicatorToMove)
      }
    },
    subIndicatorDown(indicator, subIndicatorIndex) {
      if (subIndicatorIndex < indicator.subIndicators.length - 1) {
        const subIndicators = indicator.subIndicators
        const subIndicatorToMove = subIndicators[subIndicatorIndex]
        subIndicators.splice(subIndicatorIndex, 1)
        subIndicators.splice(subIndicatorIndex + 1, 0, subIndicatorToMove)
      }
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = function () {
        let sections
        try {
          sections = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        let validStat = reader.componentThis.validate(sections)
        if (validStat === true) {
          reader.componentThis.config.content.sections = sections
        } else {
          reader.componentThis.errorText = validStat
        }
      };

      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    async submit() {
      this.loading = true
      let validStat = this.validate(this.config.content.sections)
      if (validStat === true) {
        await this.createIndicators()

        if (this.config.id === null) {
          let req = await saveDomainConfig(this.domain, this.config.content)
          if (req.ok) {
            await this.$router.push("/config/passportczn")
          } else {
            this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          }
        } else {
          let req = await updateDomainConfig(this.domain, this.config.id, this.config.content)
          if (req.ok) {
            await this.$router.push("/config/passportczn")
          } else {
            this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          }
        }
      } else {
        this.errorText = validStat
      }
      this.loading = false
    },

    getDataTypeByCode(code) {
      for (let type of this.dataTypes) {
        if (type.code === code)
          return type
      }
    }
  },
  async beforeMount() {
    this.loading = true
    await this.loadIndicators()

    if (this.$route.params.id != null) {
      let req = await getDomainConfigById(this.domain, this.$route.params.id)
      if (req.ok) {
        this.config = req.payload
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    } else {
      this.apiLoaded = true
    }
    this.loading = false
  }
};
</script>

<style lang="scss">
@import "../../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>